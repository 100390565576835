import React from "react"
import Layout from "../components/common/layout"
import { graphql } from "gatsby"
import Markdown from "react-markdown"
import markdownStyles from '../styles/common/markdown.module.scss'

// https://github.com/remarkjs/react-markdown/issues/69

const LegalTemplate = ({ data, location }) => {
  function LinkRenderer(props) {
    return <a href={props.href} target="_blank">{props.children}</a>
  }

  function flatten(text, child) {
    return typeof child === 'string'
      ? text + child
      : React.Children.toArray(child.props.children).reduce(flatten, text)
  }

  function HeadingRenderer(props) {
    var children = React.Children.toArray(props.children)
    var text = children.reduce(flatten, '')
    var slug = text.toLowerCase().replace(/\W/g, '-')
    return React.createElement('h' + props.level, {id: slug}, props.children)
  }

  return (
  <Layout
    pageMeta={{
      title: `${data.strapiDocumentiLegali.titolo}`,
      keywords: [`${data.strapiDocumentiLegali.titolo}`],
      description: `${data.strapiDocumentiLegali.titolo}`,
      link: `/${data.strapiDocumentiLegali.slug}`,
    }}
    location={location}
  >
    <h1 className='page-title'>{data.strapiDocumentiLegali.titolo}</h1>
    <Markdown className={markdownStyles.testo} renderers={{heading: HeadingRenderer, link: LinkRenderer}} source={data.strapiDocumentiLegali.testo} />
  </Layout>
)}
export default LegalTemplate

export const query = graphql`
  query LegalQuery($slug: String!) {
    strapiDocumentiLegali(slug: { eq: $slug }) {
      strapiId
      testo
      slug
      titolo
    }
  }
`